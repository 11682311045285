$header-height: 3rem;
$footer-height: 1.7rem;
$sidebar-width: 210px;
$aside-menu-width: 140px;

$color-panel: #E7E7E7;
$color-menu: #183A8F;
$color-command: #5b8cff;
$color-form-header: rgba(221, 235, 247, 0.95);

.sidebar {
  min-width: $sidebar-width;
  text-align: left;

  background: $color-panel;
  color: black;
  //  height: calc(100vh - #{$header-height} - #{$footer-height});
}

.nav-bar {
  background: $color-panel;
}

h4 {
  a {
    color: black;
  }
}

.menu {
  width: 10rem;
  text-align: left;
}

.app-header {
  background: $color-panel;
  height: $header-height;

  a {
    color: black;
    text-decoration: none;
  }

  .title {
    font-size: 1.5rem;
    font-weight: 500;
  }

  .logo {
    height: $header-height;
  }
}

.app-body {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  overflow-x: hidden;

  .main {
    flex: 1;
    min-width: 0;
  }

  .sidebar {
    // $sidebar-width is the width of the columns
    flex: 0 0 $sidebar-width;
    // put the nav on the left
    order: -1;
  }

  .aside-menu {
    // $aside-menu-width is the width of the columns
    flex: 0 0 $aside-menu-width;
  }
}

.app-footer, .app-footer-fixed {
  background: $color-panel;
  height: $footer-height;
  font-size: 0.7rem;
}

.app-footer-fixed {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
}

.active-panel {
  min-height: 2rem;

  .title {
    font-size: 1.2rem;
    font-weight: 500;
  }
}

.active-item {
  cursor: pointer;

  &:hover {
    background: lightgrey;
    border-radius: 0.4rem;
  }
}

.card-header {
  .title {
    font-size: 1.2rem;
    font-weight: 500;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.dp-panel-right, .dp-panel-left, .dp-panel-center {
  z-index: 10;
  background: $color-panel;
  position: absolute;
  bottom: 0;
}

.dp-panel-right {
  right: 0;
  transform: translate(0, 100%);
}

.dp-panel-left {
  left: 0;
  transform: translate(0, 100%);
}

.dp-panel-center {
  left: 0;
  transform: translate(-50%, 100%);
}

.dp-panel-buttons {
  min-width: 180px;
}

.dp-selectable .DayPicker-Months {
  flex-direction: row;
  flex-wrap: nowrap;
}

.dp-selectable .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
  background-color: #f0f8ff !important;
  color: #4a90e2;
}

.dp-selectable .DayPicker-Day {
  border-radius: 0 !important;
}

.dp-selectable .DayPicker-Day--start {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.dp-selectable .DayPicker-Day--end {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.card-face {
  &.success, .success {
    background: rgba(27, 194, 71, 0.24);
  }

  &.fail, .fail {
    background: rgba(217, 53, 32, 0.24);
  }

  &.noface, .noface {
    background: rgba(8, 83, 239, 0.24);
  }

  .number {
    font-size: 0.8rem;
    font-weight: bolder;
  }

  .number0 {
    font-size: 0.8rem;
  }
}

.card-check {
  &.disable, .disable {
    background: rgba(156, 160, 157, 0.24);
  }

  &.enable, .enable {
    background: rgba(27, 194, 71, 0.24);
  }

  &.ok, .ok {
    background: rgba(132, 246, 82, 0.4);
  }

  &.block, .block {
    background: rgba(213, 95, 78, 0.71);
  }

  &.hide, .hide {
    background: rgba(248, 236, 122, 0.89);
  }
}

.circular_image {
  width: 100px;
  height: 100px;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
}

.circular_image img {
  display: inline;
  margin: 0 auto;
  margin-left: -25%; //centers the image
  height: 100%;
  width: auto;
}

.raw-sample {
  font-family: monospace;
  font-size: 0.9rem;
}

.menu-item {
  padding: .375rem .75rem;
  color: white !important;
}